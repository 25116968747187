import axios from '@/http'
import { mapState, mapActions } from 'vuex'

export default {
    data () {
        return {
            id: '',
            data: {},
            columns: {},
            filters: {},
            query: {
              page: 1,
              column: this.id,
              direction: 'asc',
              per_page: 15,
              search_column: this.id,
              search_operator: 'equal',
              search_input: '',
              search_input2: ''
            }
        }
    },
    methods: {
        incluirNovo(ev) {
            this.$router.push({path: this.$route.fullPath.replace('/lista','')+'/novo'})
        },
        async getData() {
            if (typeof this.source !== "undefined" && typeof this.source !== "null" && this.source !== 'undefined/lista'){
            axios.get(this.source,{params:  {
                    column: this.query.column,
                    direction: this.query.direction,
                    page: this.query.page,
                    per_page: this.query.per_page,
                    search_column: this.query.search_column,
                    search_operator: this.query.search_operator,
                    search_input: this.query.search_input/*,
                    filters: this.filtros*/
                },
                paramsSerializer: function (params) {
                  return jQuery.param(params)
                }
                })
                .then(response => {
                  this.data = response.data.data
                  this.columns = response.data.columns
                  if (typeof response.data.filters != 'undefined'){
                    this.filters = response.data.filters
                  }else{
                    this.filters = response.data.columns
                  }
                  this.id = response.data.primary_key
                  this.$emit('setData', this.data, this.columns, this.query)
                })
                .catch(response => {
                  console.log(response.message)
                })
            }
        },    
    },
    created() {
        this.getData()
    },
    watch: {
      'padraoData' () {
        this.data = this.padraoData[0]
        this.query.page = this.padraoData[0].current_page
        this.query.direction = 'asc'        
        this.query.column = this.padraoData[1]        
        this.query.search_column = this.padraoData[1]   
        this.query.search_operator = 'like'   
        this.query.search_input = this.padraoData[2],     
        this.$emit('setData', this.data, this.columns, this.query)
      }
    },
    computed: {
        somentefiltros() {
            var self = this
            if (Object.keys(this.filters).length > 0){
              return this.filters.filter(function(item) {                
                  return typeof item.filtro != 'undefined' && (item.filtro == true || item.filtro == 'true')            
              })
            }else{
              return this.filters
            }
        },
        filtros () {
          let filters = this.somentefiltros
          for (var index in filters) {
            if (typeof filters[index].filtro_intervalo != 'undefined' && (filters[index].filtro_intervalo == true || filters[index].filtro_intervalo == 'true')){
                filters[index].filter_operator = 'between'
                filters[index].modo = 'readonly'
            }
          }
          return filters
        },
        ...mapState('Padrao',['padraoData']),        
    }          
            
}